// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keso-invest-js": () => import("/opt/build/repo/src/pages/keso-invest.js" /* webpackChunkName: "component---src-pages-keso-invest-js" */),
  "component---src-pages-prodaja-naftnih-derivata-js": () => import("/opt/build/repo/src/pages/prodaja-naftnih-derivata.js" /* webpackChunkName: "component---src-pages-prodaja-naftnih-derivata-js" */),
  "component---src-pages-proizvodnja-i-prodaja-betona-js": () => import("/opt/build/repo/src/pages/proizvodnja-i-prodaja-betona.js" /* webpackChunkName: "component---src-pages-proizvodnja-i-prodaja-betona-js" */),
  "component---src-pages-transport-js": () => import("/opt/build/repo/src/pages/transport.js" /* webpackChunkName: "component---src-pages-transport-js" */),
  "component---src-pages-zitopromet-js": () => import("/opt/build/repo/src/pages/zitopromet.js" /* webpackChunkName: "component---src-pages-zitopromet-js" */)
}

